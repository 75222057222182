body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #F5F5F5;
}

.pac-container {
  margin-top: -210px;
}

@media (prefers-reduced-motion: reduce) {
  button {
    transition: none !important;
  }
}
